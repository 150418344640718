import { clientEnvs } from '~/data/env';

import type { Window } from '~/components/root/.types/globalTypes';

const { CDN_LOCATION } = clientEnvs;

export function getSrcFromCDN(path: string) {
  if (typeof window !== 'undefined') {
    const env = (window as unknown as Window).env;

    if (env) {
      return `${env.CDN_LOCATION}${path}`;
    }

    return path;
  } else {
    return `${CDN_LOCATION}${path}`;
  }
}
